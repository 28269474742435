import { useContext, useEffect, useState } from 'react'
import { LoginContext } from '@/context/login'
import {
	faCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../ui/Button'
import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import SynapsClient from '@synaps-io/verify.js'
import AuthService from '@/services/auth'
import Hooks from '@/components/tool/Hooks'
import { AuthContext, AuthContextProvider } from '@/context/auth'
import {
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'

export default function DashboardPage() {
	const [loginState, loginDispatch] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const width = Hooks()

	function InitSynaps() {
		const Synaps = new SynapsClient(loginState.auth.session_id, loginState.auth.account_type.toLowerCase())
		Synaps.init({
			type: 'embed',
			colors: {
				primary: '52B675',
				secondary: 'FFFFFF'
			}
		})

		const SynapsModal = new SynapsClient(loginState.auth.session_id, loginState.auth.account_type.toLowerCase())
		SynapsModal.init({
			type: 'modal',
			colors: {
				primary: '52B675',
				secondary: 'FFFFFF'
			}
		})
	}

	useEffect(async () => {
		if (ready === true) {
			InitSynaps()
		}
	}, [ready])

	useEffect(async () => {
		if (loginState.auth.session_id === '' || loginState.auth.account_type === '') {
			AuthService.Me()
				.then((data) => {
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: data.email,
								firstname: data.firstname,
								lastname: data.lastname,
								profile_pic: data.profile_pic,
							},
							iam: data.iam,
							authenticated: true,
						},
					})

					loginDispatch({
						type: 'SET_LOGIN',
						payload: {
							auth: {
								session_id: data.session,
								account_type: data.session_type,
							},
						},
					})

					console.log('data')
					console.log(data)

					setReady(true)
				})
				.catch((err) => {
					console.log(err)
					authDispatch({
						type: 'SET_AUTH',
						payload: {
							user: {
								email: '',
								firstname: '',
								lastname: '',
								profile_pic: '',
							},
							iam: [],
							authenticated: false,
						},
					})
					setReady(true)
				})
			return () => { }
		} else {
			setReady(true)
		}
		return () => { }
	}, [])

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='md:flex mx-auto md:justify-between mt-3'>
							<div className={`${width >= 768 ? 'block' : 'hidden'} mt-5 mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}>
								<div className='synaps-modal' id="synaps-embed"></div>
							</div>

							<div className={`${width >= 768 ? 'hidden' : 'block'} mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}>
								<FontAwesomeIcon
									className={'text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5'}
									icon={faPassport}></FontAwesomeIcon>
								<p className="mt-1">Click on the following button to start verification process</p>
								<button type="submit" id="synaps-btn"
									className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out">Verify my identity
								</button>
							</div>

							<div className="mt-5 md:w-1/2 mr-5 ml-5 mx-auto">
								<div className='bg-white rounded-xl p-6 shadow'>
									<h3 className="text-2xl text-primary-800 mb-1 font-bold">Start your verification process</h3>
									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Complete each verification step until each status shows <span className="text-green-500">Verified</span> or <span className="text-orange-500">Pending</span>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Wait for Synaps to verify your data. The time needed for verification varies, depending on the project size and verification requirements.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											You will receive an e-mail notification if any document(s) are <span className="text-red-500">Rejected</span>.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Once every step has been <span className="text-green-500">Verified</span>, you receive an e-mail notifying that we have accepted your application.
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
					<Footer></Footer>
				</div>
			</div>


		</div>
	)
}
